import { BroadcastChannel } from "broadcast-channel";
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";

import { getUrlOrg } from "../orgs";
import { LoginEvent } from "../route/LoginListener";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const LoginSuccessView: React.FunctionComponent = () => {
  const orgId = getUrlOrg();
  const finishLogin = useCallback(async () => {
    const loginEvents = new BroadcastChannel("login");
    const message: LoginEvent = {
      orgId: orgId,
    };
    await loginEvents.postMessage(message);
    await loginEvents.close();
    window.close();
  }, [orgId]);

  useEffect(() => {
    void finishLogin();
  }, [finishLogin]);

  return (
    <Container>
      Logged in successfully! It is now safe to close this window.
    </Container>
  );
};
