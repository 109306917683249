import {
  HexId,
  HexType,
  HexVersionId,
  HexVersionString,
  MagicTypeAheadProvider,
  ProjectLanguage,
} from "@hex/common";
import { Provider, createContext, useContext } from "react";

// Right now all of these variables usually change in sync with each other, so its fine to keep in the same object for re-rendering purposes
// However, in the future we could add a selector here to reduce re-renders
/**
 * Project context contains some globally useful fields relevant to a HexVersion that can be used in any rendering of a HexVersion.
 * We use React Contexts for this instead of Redux, because we want to nest this context in some cases
 * (viewing and old version in version history dialog).
 */
export interface ProjectContext {
  hexId: HexId;
  hexVersionId: HexVersionId;
  version: HexVersionString;
  /*
   * You should almost always use projectVersionEditable instead of this field.
   * Use this judiciously (e.g., when determining if a user should be able to share a Hex).
   */
  projectEditable: boolean;
  /**
   * @deprecated
   * Read this value using the useProjectVersionEditable hook instead.
   */
  projectVersionEditable: boolean;
  projectVersionEditableOverride?: boolean;
  language: ProjectLanguage;
  trashed: boolean;
  inDialog: boolean;
  allowPublicDuplication: boolean;
  hexType: HexType;
  canViewLogic: boolean;
  magicTypeAheadProvider?: MagicTypeAheadProvider;
}

const ProjectContextInternal =
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  createContext<ProjectContext | undefined>(undefined);
ProjectContextInternal.displayName = "ProjectContext";

// cast to non-nullable, so we don't inadvertenly pass undefined and cause crashes
export const ProjectContextProvider =
  ProjectContextInternal.Provider as Provider<ProjectContext>;

export interface UseProjectContextArgs<S extends boolean> {
  /**
   * @default false
   */
  safe?: S;
}

export type UseProjectContextResult<S> = S extends false
  ? ProjectContext
  : ProjectContext | undefined;

export function useProjectContext<S extends boolean = false>(
  args: UseProjectContextArgs<S> = {},
): UseProjectContextResult<S> {
  const safe = args.safe ?? false;
  const context = useContext(ProjectContextInternal);
  if (context == null && !safe) {
    throw new Error("Project context has not been initialized!");
  }

  return context as UseProjectContextResult<S>;
}
