import { pickBy } from "lodash";

import { isTelemetryEnabledOverride } from "./data";
import { logErrorMsg } from "./logging";
import { useHexFlag } from "./useHexFlags";

const withTimeout = <T>(promise: Promise<T>, timeoutMs: number) => {
  let timeoutId: NodeJS.Timeout;
  return new Promise<T>((resolve, reject) => {
    timeoutId = setTimeout(() => reject(`timeout: ${timeoutMs}`), timeoutMs);
    promise.then(resolve).catch(reject);
  }).finally(() => {
    clearTimeout(timeoutId);
  });
};

const getStytchTelemetryId = async (telemetryTimeoutMs: number) => {
  if (window.GetTelemetryID == null) {
    return;
  }

  // TODO(NOVA-1781): add back in with datadog alternative
  // const transaction = startSentryTransaction({
  //   name: "[TELEMETRY:CLIENT] Request timing.",
  //   // Sentry expects timestamps in seconds, will silenty fail otherwise
  //   startTimestamp: Date.now() / 1000,
  // });

  let tid;
  try {
    tid = await withTimeout(window.GetTelemetryID(), telemetryTimeoutMs);
    // transaction.setTag("success", true);
  } catch (e) {
    logErrorMsg(
      e,
      "[TELEMETRY:CLIENT]: Failed to retrieve telemetry identifier.",
    );
    // transaction.setTag("success", false);
  }
  // transaction.finish(Date.now() / 1000);
  return tid;
};

/**
 * Includes telemetry IFF telemetry is enabled and the request successfully
 * completes within the specified timeout. Note that this timeout is not present
 * in the server-side routine, as network / client variability is removed as
 * concern for our intents / purposes.
 *
 * @param params
 * @param isTelemetryEnabled
 * @param telemetryTimeoutMs
 */
export const maybeIncludeTelemetry = async <T = unknown>(
  params: Record<string, T>,
  isTelemetryEnabled: boolean,
  telemetryTimeoutMs: number,
): Promise<Record<string, string | T>> => {
  if (!isTelemetryEnabled) {
    return params;
  }

  try {
    const tid = await getStytchTelemetryId(telemetryTimeoutMs);
    if (tid == null) {
      return params;
    }
    return {
      ...params,
      ...(pickBy({
        tid,
      }) as Record<string, string>), // Type is guaranteed due to `pickBy`
    };
  } catch (_) {
    return params;
  }
};

/**
 * Used in place of standard flag fetching to respect the hard-override
 * functionality configured for devs.
 *
 * See docstring in Config type:
 * https://github.com/hex-inc/hex/blob/master/packages/server/src/config.ts
 */
export const useIsTelemetryEnabled = (): boolean => {
  const isTelemetryEnabled = useHexFlag("telemetry-enabled");
  if (isTelemetryEnabledOverride != null) {
    return isTelemetryEnabledOverride;
  }
  return isTelemetryEnabled;
};
